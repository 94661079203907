import App from './App'
import store from "@vuex";
import router from "@router";
import filters from "@api/filters";
import components from "@api/components";
import prototypearr from "@api/prototype"
Vue.config.productionTip = false
// 全局方法
for (let i in prototypearr) {
  Vue.prototype["$" + i] = prototypearr[i];
}
//全局过滤器
for (let i in filters) {
  Vue.filter(i, filters[i]);
}
console.log($)
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
// 全局组件
for (let i in components) {
  Vue.component(i, components[i]);
}
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

// 跳转至pc版
router.afterEach((to, from, next) => {
  if (!navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )) {
    window.location.href = 'https://www.adjz.com' + to.path
  }
  window.scrollTo(0, 0);
});

//手动跳转https  
if (process.env.NODE_ENV !== "development") {
  let protocolStr = document.location.protocol;
  // let protocolUrl = document.location.href;
  let protocolHost = document.location.host;
  if ((protocolStr + protocolHost) != "https:m.adjz.com") {
    window.location.href = 'https://m.adjz.com';
  }
}

new Vue({
  el: '#app',
  store,
  router,
  components: {
    App
  },
  template: '<App/>'
})

